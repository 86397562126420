import React from "react";

export default ({ color }) => (
  <svg width="37" height="35" viewBox="0 0 37 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M33 7.33333H25.6667V3.66667C25.6667 1.63167 24.035 0 22 0H14.6667C12.6317 0 11 1.63167 11 3.66667V7.33333H3.66667C1.63167 7.33333 0.0183333 8.965 0.0183333 11L0 31.1667C0 33.2017 1.63167 34.8333 3.66667 34.8333H33C35.035 34.8333 36.6667 33.2017 36.6667 31.1667V11C36.6667 8.965 35.035 7.33333 33 7.33333ZM22 7.33333H14.6667V3.66667H22V7.33333Z" fill="#333333" />
    <path d="M22.5257 26.3315C21.7951 24.4568 19.683 23.5294 17.8083 24.26C15.9336 24.9906 15.0062 27.1026 15.7368 28.9774C16.4674 30.8521 18.5794 31.7795 20.4541 31.0489C22.3289 30.3183 23.2563 28.2063 22.5257 26.3315Z" fill="white" />
    <path d="M19.9713 24.2876C19.3735 22.7538 17.6455 21.9949 16.1116 22.5927C14.5778 23.1905 13.819 24.9185 14.4167 26.4524C15.0145 27.9862 16.7425 28.7451 18.2764 28.1473C19.8102 27.5495 20.5691 25.8215 19.9713 24.2876Z" fill="#F76E9C" />
    <path d="M12.8966 20.5574C14.7098 19.7385 15.5158 17.6047 14.6969 15.7915C13.878 13.9783 11.7443 13.1723 9.93106 13.9912C8.11787 14.8101 7.31183 16.9438 8.13073 18.757C8.94963 20.5702 11.0834 21.3763 12.8966 20.5574Z" fill="white" />
    <path d="M14.782 17.9288C16.2656 17.2588 16.9251 15.513 16.2551 14.0295C15.585 12.5459 13.8393 11.8864 12.3557 12.5565C10.8722 13.2265 10.2127 14.9723 10.8827 16.4558C11.5527 17.9393 13.2985 18.5988 14.782 17.9288Z" fill="#FFB000" />
    <path d="M23.1689 14.0126C21.8163 15.3952 21.8406 17.6124 23.2232 18.965C24.6058 20.3176 26.8231 20.2933 28.1757 18.9107C29.5282 17.5281 29.5039 15.3108 28.1213 13.9583C26.7388 12.6057 24.5215 12.63 23.1689 14.0126Z" fill="white" />
    <path d="M22.3906 17.0596C21.2839 18.1908 21.3038 20.005 22.435 21.1116C23.5662 22.2183 25.3804 22.1984 26.487 21.0672C27.5937 19.936 27.5738 18.1218 26.4426 17.0152C25.3114 15.9085 23.4972 15.9284 22.3906 17.0596Z" fill="#73A77B" />
  </svg>




);
